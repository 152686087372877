
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import { ref, onMounted, watch, reactive } from 'vue';
import { Modal } from "bootstrap";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import { getConcessionariaByID } from '@/services/GeralService';
import { getPdfMarca, exportXlsxMarca} from '@/services/OnePageReportGrupoService';
import usePDF from '@/composables/Pdf'
import moment from 'moment';
import { useField, useForm } from 'vee-validate';
import useEmitter from '@/composables/Emmiter';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";

export default {
  	name: "export-modal",
    components: {
        MessageModal,
        QuillEditor
    },
	setup(props){
        const store = useStore();
        const concessionariaInfo:any = ref({});
        const data:any = ref("");

        const { exportBlob } = usePDF();
        const disabledButtonExportar = ref(false);
        const loadingEmail = ref(false);
        const loadingExportFull = ref(false);
        const loadingExportPart = ref(false);
        const showClose = ref(false);
        const showExportFull = ref(true);
        const showExportPart = ref(true);
        const modalExport = ref();
        const modalShareEmail = ref();
        const contentResponse = ref();
        const responseExcel = ref();
        const contentEmail = ref("");
        const simpleSchema = {
            formEmail(value) {
                if (value && value.trim()) {
                return true;
                }
                return "* Necessário preencher o campo email";
            },
        };
        const toolbarOptions = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
            ['blockquote', 'code-block', 'link'],
            ['image', { 'color': [] }, 'clean'],
        ];

        useForm({
            validationSchema: simpleSchema,
        });

        const { showTimeAlert } = useAlert();

        const { value: formEmail, errorMessage: emailError } = useField<string>('formEmail');

        function reset(){
            showExportPart.value = true;
            showExportFull.value = true;
            contentResponse.value = "";
            responseExcel.value = "";

            clear();
        }

        const marca = reactive({
			nome: "",
			codMarca: "",
			imagem: ""
		});

        async function enviarEmail(){
            loadingEmail.value = true;
            const trataEmails = formEmail.value.replaceAll(" ", "");

            const postData = {
                titulo: geraDataTratada(),
                codConcessionaria: concessionariaInfo.value.codConcessionaria,
                conteudo: contentEmail.value,
                email: trataEmails,
                pdf: contentResponse.value,
                nomeArquivo: "ONEPAGE REPORT"
            };
           
        }
    	
        const emitter = useEmitter();

        const grupo = reactive({
			nome: "",
			codGrupo: "",
			imagem: ""
		});

		const infoDate = reactive({
			mesAnoFormatado: "",
			mes: "",
			ano: "",
			diaFim: "",
		});
        
        emitter.on("filtrar-one-page-report-marca", (filtroData) => {
			const { ano, mes } = filtroData;

            infoDate.ano = ano;
			infoDate.mes = mes;


			grupo.nome = filtroData.marca.nomeGrupo;
			grupo.codGrupo = filtroData.marca.codGrupo;
			grupo.imagem = filtroData.marca.imagemGrupo;

			marca.nome = filtroData.marca.nomeMarca;
			marca.codMarca = filtroData.marca.codMarca;
			
    	});

        store.watch(() => store.getters.concessionariaSelectedInfo, () => {
            concessionariaInfo.value = Object.assign({}, store.getters.concessionariaSelectedInfo);
            formEmail.value = concessionariaInfo.value.emailonepage;
        }, { deep: true });

        function clear(){
            contentEmail.value = "";

            const refContent:any = document.querySelector(".ql-editor");
            
            if(refContent){
                while (refContent.firstChild) {
                    refContent.removeChild(refContent.firstChild);
                }
            }
        }

        function geraDataTratada(){
            const dataInicial = moment(data.value[0]);
            const dataFinal = moment(data.value[1]);

            const mesmoAno = dataFinal.year() === dataInicial.year();
            const mesmoMes = dataFinal.month() === dataInicial.month();

            if (mesmoAno && mesmoMes) {
                return `${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
            };

            return `${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} à ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`
        }

        async function exportarPDF(exportFull = false){
            const codConcessionaria:any = concessionariaInfo.value.codConcessionaria ?? window.localStorage.getItem("filtroConcessionaria");

            disabledButtonExportar.value= true;
            const dataTratada = retornaFiltroData(data.value[0], data.value[1]);
            const distribuidor = distribuidorConfig[0]; 

            let response;
            try {

                   if(exportFull){
                    loadingExportFull.value= true;
                    response = await getPdfMarca(distribuidor.distribuidor, grupo.codGrupo, marca.codMarca, infoDate.ano, infoDate.mes);
                    loadingExportFull.value= false;
                    showExportPart.value = false;
                }
                
                contentResponse.value = response;            
            } catch(e) {
                showTimeAlert('Não foi possível completar a solicitação', "error");
            } finally {
                loadingExportFull.value= false;
                loadingExportPart.value= false;
                disabledButtonExportar.value = false;
            }
        }

       async function exportarXLSX(exportFull = false){
            const distribuidor = distribuidorConfig[0]; 
            try {
                let response;
                disabledButtonExportar.value= true;
                
                if(exportFull){
                    loadingExportFull.value= true;
                    response = await exportXlsxMarca(distribuidor.distribuidor, grupo.codGrupo, marca.codMarca, infoDate.ano, infoDate.mes);
                    loadingExportFull.value= false;
                }

                responseExcel.value = response; 
            } catch(e) {
                 showTimeAlert('Não foi possível completar a solicitação', "error");
            }
             finally {
                loadingExportFull.value= false;
                loadingExportPart.value= false;
                disabledButtonExportar.value = false;
            }
        }

        function geraFileName(routeName) {

			return `${routeName}${grupo.nome}/${marca.nome}/${infoDate.mes}/${infoDate.ano}.xlsx`
		}

          function exportaRelatorio(responsXLSX){
              if(!contentResponse.value == false){
                exportBlob(contentResponse.value, "OnePageReportMarca");
            } 
            if(!responseExcel.value == false){
                 const blob = new Blob([(responseExcel.value)], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                    console.log(blob,'blob');

                    const link = document.createElement('a');
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        link.setAttribute('href', url);
                        link.setAttribute('download', geraFileName("Relatorio_consolidado_:"));
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    };
            }
        }

        function retornaFiltroData(dataInicial, dataFinal){
            dataInicial = moment(dataInicial).format();
            dataFinal = moment(dataFinal).endOf('month').format();
            dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
            dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

            const filters = `${dataInicial}/${dataFinal}`;
            
            return filters;
        };

        onMounted(() => {
            const refModal = document.getElementById("export-modal");
            if(refModal){
                document.getElementById("layout_content")?.appendChild(refModal);
            }
            modalExport.value = new Modal(refModal);

            const refModalEmail = document.getElementById("modal-share-email");
            if(refModalEmail){
                document.getElementById("layout_content")?.appendChild(refModalEmail);
            }

            modalShareEmail.value = new Modal(refModalEmail);
        });

        return { geraFileName, exportarXLSX, clear, contentEmail, toolbarOptions, enviarEmail, modalShareEmail, loadingEmail, formEmail, emailError, reset, loadingExportFull, loadingExportPart, disabledButtonExportar, showClose, modalExport, exportaRelatorio, exportarPDF,responseExcel, contentResponse, showExportFull, showExportPart };
    },
};
